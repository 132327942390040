.rightContainer1 {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loader {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}
.mainBtn.disabled {
  background-color: rgb(226, 226, 226);
  pointer-events: none;
}
.selectFromF {
  width: 500px;
  max-width: 90%;
  margin: 10px auto;
  margin-right: 10px;
  border-radius: 8px;
}
.selectFromF > div {
  border-color: var(--main-color);
}
.modal4 {
  /* height: 35vh; */
  width: 40%;
  max-height: 380px;
  /* min-width: 320px; */
  min-height: 292px;
  max-width: 90vw;
  margin: auto;
  /* transform: translate(0%, 95%); */
  background: white;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.duplicate.css-i4bv87-MuiSvgIcon-root,
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.duplicate.css-vubbuv {
  color: white !important;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.duplicate.css-i4bv87-MuiSvgIcon-root:hover,
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.duplicate.css-vubbuv:hover {
  color: #fbb72e !important;
}

.kortele.idealus {
  box-shadow: 0 0 0 3px #fbb72e;
}
