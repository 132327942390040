@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
:root {
  --main-color: #19294f;
  --main-choose: #fbb72e;
  --main-inactive: grey;
  --main-white: white;
  --place-holder-clr: grey;

  font-family: Roboto !important;
}

body {
  background: var(--main-color);
  font-family: Roboto;
}
h2 {
  color: var(--main-white);
  font-style: normal;
  font-weight: 500;
  font-size: 29px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.0041em;
}
.navContainer {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
.navItem {
  margin: 20px;
  color: #ffffff;
  text-decoration: none;
}
.navItem1 {
  margin: 20px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
.centerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-wrap: wrap; */
}
.centerContainer1 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-wrap: wrap; */
  padding: 20px;
}
.toSides {
  display: flex;
  justify-content: space-between;
}
.column {
  flex-direction: column;
}
.fullScreen {
  /* width: 100vw; */
  height: 100vh;
}
.chooseBtn {
  width: 240px;
  /* margin: 10px; */
  height: 40px;
  background: white;
  border-radius: 10px 0px 0px 10px;
  font-size: 15px;
  line-height: 35px;
  font-weight: bold;
  color: #19294f;
  /* border-color: white; */
  width: 50%;
}
.chooseBtn1 {
  width: 240px;
  /* margin: 10px; */
  height: 40px;
  background: white;
  border-radius: 0px 10px 10px 0;
  font-size: 15px;
  line-height: 35px;
  font-weight: bold;
  color: #19294f;
  border-color: white;
  width: 50%;
}
.chooseBtn.off {
  color: white;
  background-color: #19294f;
}
.chooseBtn1.off {
  color: white;
  background-color: #19294f;
  border-color: 1px solid white;
}
.chooseBtn.off:hover {
  color: white;
  background-color: #304f9762;
}
.chooseBtn1.off:hover {
  color: white;
  background-color: #304f9762;
  border-color: 1px solid white;
}

.flex {
  display: flex;
}
.mainBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 17px 129px;
  width: 499px;
  max-width: 72vw;
  background: var(--main-white);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  letter-spacing: -0.0041em;
  font-weight: bold;
  color: var(--main-color);
  align-self: center;
  margin: 30px 0 0 0;
}

.mainBtn2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 17px 129px;
  width: 499px;
  max-width: 72vw;
  background: var(--main-white);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  letter-spacing: -0.0041em;
  font-weight: bold;
  color: var(--main-color);
  align-self: center;
  margin: 30px 10px 0 10px;
}

.mainBtn:hover {
  background-color: #fbb72e;
  color: #19294f;
}
.mainBtn:disabled {
  background-color: rgb(226, 226, 226);
  pointer-events: none;
}

.socPrisijungimas {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  padding: 17px 10px;
  width: 48%;
  background: var(--main-white);
  border-radius: 8px !important;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  letter-spacing: -0.0041em;
  font-weight: bold !important;
  color: var(--main-color);
  margin-top: 50px;
  cursor: pointer;
}

.socPrisijungimas:hover {
  /* background: rgba(255, 255, 255, 0.767); */
  opacity: 0.9;
}
.openModalLink {
  color: #ffffff;
  padding: 10px 0 20px 0;
  cursor: pointer;
}
.input {
  /* Systembackground/ Primary */
  padding: 5px 10px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #1c2f5d;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  margin: 10px;
  color: black;
  font-size: medium;
  max-width: 500px;
}
.input::placeholder {
  /* text-transform: capitalize; */
  color: var(--place-holder-clr);
}
.textarea {
  /* Systembackground/ Primary */
  padding: 5px 10px;
  height: 69px;
  background: #ffffff;
  border: 1px solid #1c2f5d;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  margin: 10px;
}
button {
  border: none;
  border-radius: 100px;
}
.closeBtn {
  position: absolute;
  top: 5%;
  right: 5%;
}
.closeBtn button {
  border-radius: 100px;
  padding: 5px 8px;
  justify-content: center;
  line-height: 1.2;
  background-color: unset;
  color: #19294f;
  font-weight: bold;
  font-size: large;
}
.mt-20 {
  margin-top: 20px;
}
.rightContainer {
  width: 500px;
  max-width: 90%;
  margin: 30px 10px 10px 10px;
  place-self: flex-start;
  background-color: #111d3a;
  padding: 30px 30px 10px 30px;
  border-radius: 10px;
}
.rightContainer1 {
  width: 500px;
  max-width: 90%;
  margin: 30px 10px 10px 10px;
  place-self: flex-start;
  background-color: #111d3a;
  padding: 30px 30px 30px 30px;
  border-radius: 10px;
}
.rightContainer2 {
  width: 300px;
  max-width: 90%;
  /* margin: -8px 10px 10px 10px; */
  place-self: flex-start;
  /* background-color: #111d3a; */
  padding: 30px 30px 10px 30px;
  border-radius: 10px;
}

.select {
  width: 500px;
  max-width: 100%;
  margin: 10px;
  margin-right: 10px;
  border-radius: 8px;
}
.select .css-14el2xx-placeholder {
  /* selecto placeholderis */
  color: var(--place-holder-clr);
}
.select .css-1s2u09g-control {
  color: black;
}

.selfCenter {
  margin: 50px auto;
}
.checkIt {
  width: 100%;
  position: relative;
  margin: 20px 0px 0px 0px;
}
.hiddenCheck {
  position: absolute;
  top: 50%;
  bottom: 50%;
  z-index: -2;
  left: 50%;
  right: 50%;
}
.modal {
  height: 35vh;
  width: 40%;
  /* min-width: 320px; */
  min-height: 292px;
  max-width: 90vw;
  margin: auto;
  /* transform: translate(0%, 95%); */
  background: white;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}
.modal1 {
  /* height: 35vh; */
  width: 40%;
  max-width: 90vw;
  margin: auto;
  /* transform: translate(0%, 30%); */
  background: white;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border-color: #ffffff;
}
.modal2 {
  /* height: 35vh; */
  width: 35%;
  max-width: 90vw;
  margin: auto;
  /* transform: translate(0%, 30%); */
  background: white;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border-color: #ffffff;
}

.modal3 {
  /* height: 35vh; */
  width: 20%;
  /* min-width: 20%; */
  /* min-height: 37px; */
  max-width: 90vw;
  margin: auto;
  padding: 30px 30px 10px 30px;
  /* transform: translate(0%, 150%); */
  background: white;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border-color: #ffffff;
  text-align: center;
}
.modalBtn {
  background-color: var(--main-color);
  color: var(--main-white);
  font-weight: 300;
}
.modalBtnContainer {
  display: inline-flex;
}
.modalBtn1 {
  background-color: #19294f;
  color: var(--main-white);
  font-weight: 300;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 30px;
  /* width: 50px; */
  max-width: -5vw;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  letter-spacing: -0.0041em;
  align-self: center;
  margin: 0px 20px;
}

button.mainBtn.modalBtn {
  width: 300px;
  padding: 10px;
}
.radioMargin {
  padding: 3px 0px;
}

.radioContainer {
  margin: 10px 0px 30px 0px;
}

.radioContainer1 {
  margin: 10px 0px 30px 0px;
  height: 35vh;
  padding: 0px 20px;
}

.forma {
  margin: 35px 0px 0px 0px;
  height: 500px;
  /* overflow: hidden; */
  overflow: auto;
  padding: 0px 20px 0px 0px;
}

.checkBox {
  margin: 5px 0px 0px 5px;
  color: #ffffff;
}
.checkBox1 {
  margin: 0;
  color: #ffffff;
}
.checkBox2 {
  margin: 20px;
  color: #000000;
}

.columnName {
  margin: 20px 0px 0px 10px;
  color: #ffffff;
}

.dateMargin {
  margin: -15px 0px 0px 0px;
}

::placeholder {
  font-family: roboto;
  font-size: medium;
}

.kortele {
  background-color: var(--main-white);
  width: 80%;
  min-width: 80%;
  margin: 10px;
  border-radius: 9px;
  border-color: #ffffff;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  -o-border-radius: 9px;
  display: flex;
  --hover-box-bg: lightgrey;
  --hover-border-color: white;
  --hover-text: rgb(0, 0, 0);
  --hover-icon: rgb(0, 0, 0);
  box-shadow: 0 0 1px 0 white;
}
@media screen and (max-width: 766px) {
  .kortele {
    margin: 10px auto;
  }
  .korteles {
    margin: auto;
  }
}
@media screen and (min-width: 767px) {
  .korteles {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    justify-items: center;
    max-width: 900px;
    margin: auto;
    padding: 20px 0px 0px 0px;
  }
}

.smallBtnContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  padding: 12px;
}

.smallBtn {
  margin: 10px 2px;
  background-color: var(--main-color);
  color: var(--main-white);
  padding: 4px 10px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: #19294f !important;
  cursor: pointer !important;
}

.kortele .smallBtn:hover {
  transition: grey;
  color: white;
  background-color: grey !important;
}
.close {
  display: flex;
  align-items: center;
  min-width: 40px;
  justify-content: center;
  font-size: large;
  padding: 0px 5px;
}

.cardInfo {
  /* padding: 5px; */
  align-self: center;
  margin: 0px -8px;
  align-items: center;
  justify-content: center;
  align-content: center;
  align-self: center;
}

.candidateInfo {
  margin: 0px 10px;
}
.candidateInfo1 {
  margin: 0px 10px;
  padding: 0px;
}

h6 {
  margin: 10px 0px 0px 0px;
  font-weight: 500;
  color: grey;
}
h4 {
  margin: 0px 0px 10px 0px;
}

.navBar {
  line-height: 2;
  display: flex;
  justify-content: space-between;
  margin: 20px 30px 0px 30px;
  align-items: center;
}
@media screen {
}

.modalInfo {
  overflow-y: auto;
  /* white-space: nowrap; */
  margin: 20px 0px 30px 0px;
  width: 35vw;
}
.modalInfo::-webkit-scrollbar-track {
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 2px 0;
  background-color: lightgrey;
}
.modalInfo::-webkit-scrollbar {
  width: 10px;
}

.modalInfo::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #19294f;
  border: 1px solid #19294f;
}

.inModalInfo {
  margin: auto;
}
.inModalInfo1 {
  margin: 5px 0px 20px 0px;
  /* color: grey; */
  font-weight: 500;
  white-space: pre-wrap;
}

.dbMain {
  border-radius: 20px;
  background-color: #ffffff;
  padding: 20px;
  max-width: 90vw;
  margin: 0px 10px;
  margin: auto;
}

.dbData.MuiDataGrid-root.css-18lolh3-MuiDataGrid-root {
  border: none !important;
}

.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  color: #1c2f5d !important;
  margin: 10px 0px;
}

button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButtonBase-root.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  color: #1c2f5d !important;
}

.MuiDataGrid-virtualScroller.css-1pans1z-MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
  border: 1px solid lightgrey !important;
  border-radius: 5px !important;
  padding: 2px 0 !important;
  background-color: lightgrey !important;
}
.MuiDataGrid-virtualScroller.css-1pans1z-MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

.MuiDataGrid-virtualScroller.css-1pans1z-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #19294f !important;
  border: 1px solid #19294f !important;
}

.MuiDataGrid-main div {
  color: black !important;
}
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft[data-field="miestas"],
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft[data-field="sutarties_pobudis"],
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft[data-field="studiju_sritis"],
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft[data-field="darbo_sritis"],
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft[data-field="patirtis"],
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft[data-field="kontaktinis_el_pastas"],
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft[data-field="kalbos_ir_ju_lygiai"] {
  display: flex;
  /* overflow: auto; */
  flex-wrap: wrap;
  flex-wrap: wrap;
  white-space: pre-wrap;
  max-height: unset !important;
  padding: 10px 0;
  margin: 0px 0px 0px 3px;
}
/* .MuiDataGrid-root.css-18lolh3-MuiDataGrid-root {
  height: 70vh;
} */

.MuiDataGrid-cell--textLeft::-webkit-scrollbar-track {
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 2px 0;
  background-color: lightgrey;
  margin: 5px 5px;
}
.MuiDataGrid-cell--textLeft::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.MuiDataGrid-cell--textLeft::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #19294f;
  border: 1px solid #19294f;
}

.forma::-webkit-scrollbar-track {
  border: 0px solid white;
  border-radius: 5px;
  padding: 2px 0;
  background-color: #19294f;
  margin: 5px 5px;
}
.forma::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.forma::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: white;
  border: 1px solid white;
}

.css-18lolh3-MuiDataGrid-root
  .MuiDataGrid-columnHeaderTitleContainer
  .MuiDataGrid-columnHeaderTitle.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  width: 75px !important;
  white-space: normal;
  line-height: 1;
  overflow: visible;
  text-align: center;
  justify-content: center;
}
.MuiDataGrid-columnHeaderTitleContainer {
  justify-content: flex-start;
  width: auto;
}

:focus-visible {
  outline: unset;
}

.MuiDataGrid-cell.MuiDataGrid-cell--textLeft[data-field="miestas"]:hover,
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft[data-field="sutarties_pobudis"]:hover,
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft[data-field="studiju_sritis"]:hover,
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft[data-field="darbo_sritis"]:hover,
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft[data-field="kontaktinis_el_pastas"]:hover,
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft[data-field="kalbos_ir_ju_lygiai"]:hover {
  overflow: auto !important;
  background: #19294f !important;
  height: 100% !important;
  background-color: #ffffff !important;
  z-index: 100 !important;
  border-radius: 5px !important;
  padding: 15px !important;
}

/* .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable {
  width: unset!important;
  max-width: unset!important;
  min-width: unset!important;
} */

.adContainer {
  width: 100%;
  min-width: 100%;
}
.adContainer1 {
  margin: 91px 50px;
  width: 100%;
  min-width: 100%;
}

.mainSkelbimai {
  display: flex;
  justify-content: center;
}

.leftSkelbimai {
  margin: 91px 50px;

  flex: 30%; /* min-width: 300px; */
}

.rightSkelbimai {
  flex: 70%;
}

.delete {
  padding: 0px 10px 0px 20px;
}

.switch {
  display: flex;
  align-items: center;
}
.MuiSwitch-colorPrimary {
  color: #19294f;
}
/* span.MuiSwitch-thumb.css-jsexje-MuiSwitch-thumb {
  
  background-color: #19294f;
} */

.titleProps {
  color: white;
  margin: 12px;
  font-size: large;
  cursor: pointer;
}

.react-date-picker__wrapper {
  border: none;
}

.kortele:hover {
  /* background-color: var(--hover-box-bg); */
  /* transition: var(--transition-time); */
  cursor: pointer;
  /* outline: 2px solid #fbb72e; */
  box-shadow: 0 0 0 3px #fbb72e;
}
.kortele:hover .close {
  color: var(--hover-text);
}
.kortele .close:hover {
  color: var(--hover-icon);
  transition: all 0.3s;
}
.kortele:hover .candidateInfo {
  color: var(--hover-text);
  transition: all 0.3s;
}
.kortele .smallBtn:hover {
  transition: var(--transition-time);
  color: var(--hover-box-bg);
  background-color: var(--hover-text);
}

.adsList {
  color: white;
  /* border-bottom: 1px; */
  padding: 10px 10px;
  background-color: white;
  color: black;
  border-radius: 10px;
  margin: 55px 10px 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.adsList1 {
  color: white;
  /* border-bottom: 1px; */
  padding: 15px 10px;
  background-color: white;
  color: #19294f;
  border-radius: 10px;
  margin: 10px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  font-family: "roboto";
  width: 100%;
  /* text-align: center; */
  justify-content: center;
  text-transform: uppercase;
}
.adsList2 {
  color: white;
  /* border-bottom: 1px; */
  padding: 10px 10px;
  background-color: white;
  color: #19294f;
  border-radius: 10px;
  margin: 10px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  font-family: "roboto";
  /* width: 100%; */
  /* text-align: center; */
  justify-content: center;
}

.adsList3 {
  color: white;
  /* border-bottom: 1px; */
  padding: 10px 10px;
  background-color: white;
  color: #19294f;
  border-radius: 10px;
  margin: 10px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  font-family: "roboto";
  /* width: 100%; */
  /* text-align: center; */
  /* justify-content: center; */
}

.adsList1:hover {
  background-color: #fbb72e;
  color: white;
}

.adsList1:focus-within {
  background-color: #fbb72e;
  color: #19294f;
  font-weight: 800;
}
.adsList2:hover {
  background-color: #fbb72e;
  color: white;
}

.adsList2:focus-within {
  background-color: #fbb72e;
  color: #19294f;
  font-weight: 800;
}
.adsList3:hover {
  background-color: #fbb72e;
  color: white;
}

.adsList3:focus-within {
  background-color: #fbb72e;
  color: #19294f;
  font-weight: 800;
}

.adsai {
  margin-top: 60px;
}

.changeLng {
  position: absolute;
}

.navLogo {
  width: 50px;
  height: 50px;
}

.logo {
  width: 100px;
  height: 100px;
  margin-bottom: 100px;
}

.languageSelector {
  margin: 0 20px;
  background: none;
  color: white;
  padding: 10px;
}

.changeLng {
  display: none;
}

.currentLng {
  text-decoration: underline;
  text-transform: uppercase;
}

.languageSelector:hover .changeLng {
  display: block;
  cursor: pointer;
  text-transform: uppercase;
}

.language:hover {
  font-weight: bold;
}

.loginBg {
  background-color: #111d3a;
  border-radius: 10px;
  padding: 30px;
}

.react-date-picker__inputGroup__input:invalid {
  background: rgb(255 255 255 / 10%) !important;
}
.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: none !important;
}

.react-calendar__tile--now {
  background: unset !important;
  /* color: black!important; */
  /* margin: 0; */
  /* padding: 0; */
  /* width: 2px; */
}
.react-calendar__tile--active {
  background: #19294f !important;
  color: white;
}

.politika {
  padding: 20px;
}

.socialDownload {
  height: 70vh;
}
.socialDownload > div {
  margin: 10px;
}
button#mui-4 {
  display: none !important;
}
#mui-24 {
  display: none !important;
}

.css-1rtnrqa {
  color: #19294f !important;
}

.css-ta7xl9 {
  color: #19294f !important;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
  color: #19294f !important;
}

.dbData.MuiDataGrid-root.css-fimgsr {
  border: unset !important;
}

.regCon {
  margin: 45px 0 0 0;
}

textarea.textarea {
  font-size: medium !important;
  font-family: "roboto" !important;
  max-width: 500px;
}
button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.adsList1 {
  background-color: white;
  border-radius: 10px;
  margin: 10px 0;
  font-weight: 700;
  color: #1c2f5d;
}
button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.adsList1:hover {
  background-color: #fbb72e;
  border-radius: 10px;
  margin: 10px 0;
  color: white;
}
.MuiTabs-root.MuiTabs-vertical {
  border: unset;
}

.socialDownload {
  height: 70vh;
}
.socialDownload > div {
  margin: 10px;
}
button#mui-4 {
  display: none;
}

.none {
  display: none;
}
.modal2 {
  min-width: 320px;
}
button.modalBtn1.disabled {
  background: grey;
}

.checkBox2 {
  display: flex;
}
.toSides.disabled.socialButtons {
  position: relative;
}
.toSides.disabled.socialButtons button {
  pointer-events: none;
  background-color: #b5b5b5 !important;
}
.toSides.disabled.socialButtons button div {
  background-color: #b5b5b5 !important;
}
.flex.column.socialCheckbox {
  display: none;
}
.toSides.disabled.socialButtons:hover .flex.column.socialCheckbox {
  display: flex;
  position: absolute;
  bottom: -10px;
  background: #19294f;
  padding: 10px;
  border-radius: 10px;
  left: 50%;
  right: 50%;
  width: fit-content;
  transform: translate(-50%, -50%);
}
body.ar {
  direction: rtl;
}

button.socPrisijungimas,
.socialButtons span {
  color: #111d3a !important;
  font-size: 17px !important;
  font-weight: 600 !important;
}

.ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and(max-width:767px) {
  .mainSkelbimai {
    display: block;
  }
}

@media (prefers-color-scheme: dark) {
  .input {
    padding: 5px 10px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #1c2f5d;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    margin: 10px;
    color: black;
    font-size: medium;
    max-width: 500px;
  }
  .select {
    color: black;
  }
  .radioContainer {
    color: black;
  }
  h3 {
    color: black;
  }
  h1,
  h2 {
    color: white;
  }
}

.pass {
  position: relative;
  width: 100%;
  margin: 0 0 0 -20px;
}

.passIcon {
  position: absolute;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.passIcon {
  right: 400px !important;
  left: 95% !important;
  top: 50% !important;
  transform: translate(0%, -50%) !important;
  /* justify-content: flex-end; */
  /* margin-right: 0px; */
}

.duplicate {
  padding: 0 0 0 15px;
}
